import { StyleProject as PicassoStyleProject } from "cadius-backend";
import {
  ICON,
  ItemDataset,
  ProjectAction,
  ProjectActions,
  svgIcon,
} from "cadius-components";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { AppRoutes } from "../../routes";
import { ProjectNotFound } from "../ProjectNotFound";

declare const CADIUS_CAD3DR_URL: string;

interface Props {
  cleanupStyleProject: () => void;
  fetchStyleProject: (requestedProjectId: string) => void;
  onActionClick: (dataset: ItemDataset) => void;
  project?: PicassoStyleProject;
  requestedProjectId: string;
}

export const StyleProject: React.FC<Props> = ({
  cleanupStyleProject,
  fetchStyleProject,
  onActionClick,
  project,
  requestedProjectId,
}) => {
  // TODO: infer allowed actions from project and authenticated user
  const actions: ProjectAction[] = [
    {
      actionId: "delete",
      icon: ICON.DELETE,
      label: "Delete",
      requireConfirmation: true,
    },
  ];

  useEffect(() => {
    fetchStyleProject(requestedProjectId);
    return function cleanup() {
      cleanupStyleProject();
    };
  }, [requestedProjectId]);

  return (
    <div style={{ backgroundColor: "steelblue", gridArea: "main" }}>
      {project ? (
        <div>
          <Link to={AppRoutes.StyleProjects}>
            {svgIcon(ICON.UNDO)}
            <span>{`Go back to style projects`}</span>
          </Link>
          <h2>{project.name}</h2>
          <ul>
            {project.notes.map(({ id, message, username }) => {
              return <li key={id}>{`${message} (by ${username})`}</li>;
            })}
          </ul>
          <ProjectActions
            actions={actions}
            onActionClick={onActionClick}
            projectId={project.id}
          />
          <a
            href={`${CADIUS_CAD3DR_URL}/#/projects/${project.id}`}
            target="_blank"
          >{`Open ${project.name} in cadius-cad3dr`}</a>
        </div>
      ) : (
        <ProjectNotFound
          id={requestedProjectId}
          to={AppRoutes.StyleProjects}
          toLabel={`Go back to style projects`}
        />
      )}
    </div>
  );
};
