import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";

import { Action as NetworkAction } from "../redux/actions/network";
import { Action as UiAction } from "../redux/actions/ui";
import { networkMiddleware, projectsMiddleware } from "./middlewares";
import { rootReducer } from "./reducers";

const logger = createLogger({
  collapsed: true,
  duration: true,
  level: "log",
  // Avoid logging these actions: there are too many of them!
  predicate: (getState, action) => {
    const test = action.type !== NetworkAction.BEGIN_NETWORK_REQUEST &&
      action.type !== NetworkAction.END_NETWORK_REQUEST &&
      action.type !== UiAction.SET_LOADER;
    return test;
  },
});

const enhancer = applyMiddleware(
  projectsMiddleware,
  networkMiddleware,
  logger
);

export const store = createStore(rootReducer, enhancer);
