import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { RemeshingProjectRoute } from "../../route-components";
import { requestOperationOnRemeshingProject } from "../actions/project";

const mapStateToProps = null;

function mapDispatchToProps(dispatch: Dispatch) {
  const actionCreators = bindActionCreators(
    {
      onActionClick: requestOperationOnRemeshingProject,
    },
    dispatch
  );

  return {
    ...actionCreators,
  };
}

const enhance = connect(mapStateToProps, mapDispatchToProps);

export const RemeshingProjectRouteConnected = enhance(RemeshingProjectRoute);
