import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { RemeshingProject } from "../../components/RemeshingProject";
import {
  fetchRemeshingProject,
  requestOperationOnRemeshingProject,
  setRemeshingProject,
} from "../actions/project";
import { AppState } from "../reducers/interfaces";
import { getRemeshingProject } from "../reducers/projects";

function mapStateToProps(state: AppState) {
  return {
    project: getRemeshingProject(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const actionCreators = bindActionCreators(
    {
      cleanupRemeshingProject: setRemeshingProject,
      fetchRemeshingProject,
      onActionClick: requestOperationOnRemeshingProject,
    },
    dispatch
  );

  return {
    ...actionCreators,
  };
}

const enhance = connect(mapStateToProps, mapDispatchToProps);

export const RemeshingProjectConnected = enhance(RemeshingProject);
