import { Dashboard, Sidebar, WaitingDialog } from "@comelz/ccloud-react-components";
import { SvgIconRegistry } from "cadius-components";
import React from "react";
import { Provider } from "react-redux";
import { connect } from "react-redux";
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { Link } from "react-router-dom";

import { AuthContextProvider } from "../../context-providers";
import {
  RemeshingProjectRouteConnected,
  RemeshingProjectsRouteConnected,
  store,
  StyleProjectRouteConnected,
  StyleProjectsRouteConnected,
  UploadCalRouteConnected,
  UploadLastRouteConnected,
} from "../../redux";
import { AppState } from "../../redux/reducers/interfaces";
import { AppRoutes } from "../../routes";

import "./app.scss";

const sidebarItems = [
  {
    id: "projects",
    label: "Published Projects",
    renderAs: () => {
      return (<Link to={AppRoutes.StyleProjects}>Published Projects</Link>);
    },
  },
  {
    id: "lasts",
    label: "Manage Lasts",
    renderAs: () => {
      return (<Link to={AppRoutes.RemeshingProjects}>Manage Lasts</Link>);
    },
  },
];

interface IAppUIProps {
  // the element to show in the app main area
  children: React.ReactNode;

  uploadInProgress: boolean;
}

function mapStateToProps(state: AppState) {
  return {
    uploadInProgress: state.projects.styleUploadInProgress || state.projects.remeshingUploadInProgress,
  };
}

const AppUI = connect(mapStateToProps)((props: IAppUIProps) => {
  const location = useLocation();
  let section = "";
  switch (location.pathname) {
    case AppRoutes.StyleProjects:
      section = "projects";
      break;
    case AppRoutes.RemeshingProjects:
      section = "lasts";
      break;
  }
  const sidebar = (
    <Sidebar
      items={sidebarItems}
      selected={section}
      onItemSelected={() => { }} />);
  return (
    <div className="app">
      <div className="app-header">
        <h1><span>Caligola</span> Style</h1>
        <h2>Dashboard</h2>
      </div>
      <Dashboard sidebar={sidebar}>{props.children}</Dashboard>
      {props.uploadInProgress && <WaitingDialog text="uploading project" progress={Infinity} />}
    </div>
  );
});

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <div className="ui">
        <AuthContextProvider>
          <SvgIconRegistry />
          <HashRouter>
            <AppUI>
              <Switch>
                <Route
                  path={AppRoutes.StyleProjects}
                  exact={true}
                  component={StyleProjectsRouteConnected}
                />
                <Route
                  path={`${AppRoutes.StyleProjects}/:id`}
                  exact={true}
                  component={StyleProjectRouteConnected}
                />
                <Route
                  path={AppRoutes.RemeshingProjects}
                  exact={true}
                  component={RemeshingProjectsRouteConnected}
                />
                <Route
                  path={`${AppRoutes.RemeshingProjects}/:id`}
                  exact={true}
                  component={RemeshingProjectRouteConnected}
                />
                <Route
                  path={AppRoutes.UploadCal}
                  exact={true}
                  component={UploadCalRouteConnected}
                />
                <Route
                  path={AppRoutes.UploadLast}
                  exact={true}
                  component={UploadLastRouteConnected}
                />
                <Route>
                  <Redirect to={AppRoutes.StyleProjects} />
                </Route>
              </Switch>
            </AppUI>
          </HashRouter>
        </AuthContextProvider>
      </div>
    </Provider>
  );
};
