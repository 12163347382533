export enum Action {
  SET_LOADER = "SET_LOADER",
  TOGGLE_BETWEEN_LIST_AND_GRID = "TOGGLE_BETWEEN_LIST_AND_GRID",
}

export interface SetLoaderAction {
  payload: {
    progress?: number;
  };
  type: Action.SET_LOADER;
}

export function setLoader(progress?: number): SetLoaderAction {
  return { payload: { progress }, type: Action.SET_LOADER };
}

export interface ToggleAction {
  type: Action.TOGGLE_BETWEEN_LIST_AND_GRID;
}

export function toggleBetweenListAndGrid(): ToggleAction {
  return { type: Action.TOGGLE_BETWEEN_LIST_AND_GRID };
}

export interface UploadAction {
  payload: {
    target?: "style" | "remeshing",
  };
  type: "SET_UPLOAD_IN_PROGRESS";
}

export function setUploadInProgress(target?: "style" | "remeshing"): UploadAction {
  return {
    payload: {
      target,
    },
    type: "SET_UPLOAD_IN_PROGRESS",
  };
}
