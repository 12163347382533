import { Table } from "@comelz/ccloud-react-components";
import { ProjectDigest } from "cadius-backend";
import React from "react";

interface IProjectList<T> {
    projects: T[];

    // Called when the user select a project
    onClick: (projectId: string) => void;

    actions?: (project: T) => React.ReactElement;
}

const rtf = new Intl.RelativeTimeFormat([], { numeric: "auto", style: "narrow" });
const dtf = new Intl.DateTimeFormat([], { dateStyle: "medium", timeStyle: "short" } as Intl.DateTimeFormatOptions);
function formatRelativeTime(timestamp: string): string {
    const utc = new Date(timestamp);
    const now = new Date(Date.now());
    const d = Math.round(utc.getTime() / 60000);
    const n = Math.round(now.getTime() / 60000);
    let diff = d - n;
    if (Math.abs(diff) < 60) {
        return rtf.format(diff, "minute");
    }
    diff = Math.round(diff / 60);
    if (Math.abs(diff) < 24) {
        return rtf.format(diff, "hour");
    }
    diff = Math.round(diff / 24);
    if (Math.abs(diff) < 2) {
        return rtf.format(diff, "day");
    }
    return dtf.format(utc);
}

// ProjectList displays the given projects in a table
export function ProjectList<T extends ProjectDigest>(props: IProjectList<T>) {
    const { projects, onClick } = props;

    const columns = [
        {
            id: "name",
            label: "Project name",
            renderAs: (value: string, rowIx: number) => {
                const projectId = projects[rowIx].id;
                const handler = (evt: any) => {
                    evt.preventDefault();
                    onClick(projectId);
                };
                return (<a href="#" onClick={handler}>{value}</a>);
            },
        },
        {
            id: "updated_at",
            label: "Last modification",
        },

    ];
    if (props.actions) {
        columns.push({
            id: "actions",
            label: "",
            renderAs: (value: any, rowIx: number) => {
                return props.actions!(projects[rowIx]);
            },
        });
    }

    const rows = projects.map((prj) => {
        return {
            id: prj.id,
            name: prj.name,
            updated_at: prj.timestamp ? formatRelativeTime(prj.timestamp) : "",
        };
    });

    return (
        <Table columns={columns} rows={rows} />
    );
}
