import { CTAButton, WarnButton } from "@comelz/ccloud-react-components";
import { FormFieldWithDropzone, NewProjectFormData } from "cadius-components";
import clsx from "clsx";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import "./UploadCalRoute.scss";

interface Props extends RouteComponentProps {
  onSubmitNewProjectData: (data: NewProjectFormData) => void;
}

export const UploadCalRoute: React.FC<Props> = ({
  onSubmitNewProjectData,
}) => {
  const formRef: React.RefObject<HTMLFormElement> = React.createRef();

  const [nameValue, setNameValue] = useState("");
  const [nameError, setNameError] = useState(false);
  const [fileValue, setFileValue] = useState(undefined as Blob | undefined);
  const [fileError, setFileError] = useState(false);

  const onNameChange = (evt: any) => {
    const value: string = evt.target.value;
    setNameValue(value);
    setNameError(value === "");
  };

  const onUpload = () => {
    onSubmitNewProjectData({
      calFile: fileValue!,
      projectName: nameValue,
    });
  };

  const onCancel = () => {
    formRef.current!.reset();
  };

  // submitEnabled controls if the forms can be submitted
  // to be submitted the form must be valid (no errors) and must have all the required values
  const submitEnabled = !(nameError || fileError) && nameValue !== undefined && fileValue !== undefined;
  return (
    <div className="upload-cal">
      <div className="cmp-panel-header">
        <h1>Upload new CAL</h1>
      </div>
      <form className="cmp-form" ref={formRef}>
        <div className={clsx("cmp-field", nameError ? "invalid" : "")}>
          <label htmlFor="upload-cal-project-name">Project name</label>
          <input
            type="text"
            id="upload-cal-project-name"
            name="upload-cal-project-name"
            placeholder="The project name"
            onChange={onNameChange}
          />
        </div>
        <div className={clsx("cmp-field", fileError ? "invalid" : "")}>
          <FormFieldWithDropzone
            accept=".cal"
            clearError={() => setFileError(false)}
            errorMessage={fileError ? "unsupported file type" : ""}
            hint="The .cal file to use to create the new project."
            label="Caligola .cal file"
            name="calFile"
            setError={() => setFileError(true)}
            setValue={(name: string, value: Blob) => setFileValue(value)}
          />
        </div>
        <div className="cmp-form-buttons">
          <WarnButton text="cancel" onClick={onCancel} />
          <CTAButton
            text="Upload"
            disabled={!submitEnabled}
            onClick={onUpload}
          />
        </div>
      </form >
    </div>
  );
};
