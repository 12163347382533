import { ICON, svgIcon } from "cadius-components";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  /**
   * The id the user was looking for.
   */
  id: string;

  /**
   * The route the application will redirect to if the user clicks the relevant
   * UI element.
   */
  to: string;

  /**
   * The label suggesting the user where the application will redirect him.
   */
  toLabel: string;
}

export const ProjectNotFound: React.FC<Props> = ({ id, to, toLabel }) => {
  return (
    <div>
      <p>{`Project ${id} not found. Maybe it was deleted or moved.`}</p>
      <Link to={to}>
        {svgIcon(ICON.UNDO)}
        <span>{toLabel}</span>
      </Link>
    </div>
  );
};
