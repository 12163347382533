import { RemeshingProject as PicassoRemeshingProject } from "cadius-backend";
import {
  ICON,
  ItemDataset,
  ProjectAction,
  ProjectActions,
  svgIcon,
} from "cadius-components";
import clsx from "clsx";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { AppRoutes } from "../../routes";
import { ProjectNotFound } from "../ProjectNotFound";

declare const CADIUS_FORMS_URL: string;

export interface Props {
  cleanupRemeshingProject: () => void;
  fetchRemeshingProject: (requestedProjectId: string) => void;
  onActionClick: (dataset: ItemDataset) => void;
  project?: PicassoRemeshingProject;
  requestedProjectId: string;
}

export const RemeshingProject: React.FC<Props> = ({
  cleanupRemeshingProject,
  fetchRemeshingProject,
  onActionClick,
  project,
  requestedProjectId,
}) => {
  const actions: ProjectAction[] = [
    {
      actionId: "delete",
      icon: ICON.DELETE,
      label: "Delete",
      requireConfirmation: true,
    },
  ];

  useEffect(() => {
    fetchRemeshingProject(requestedProjectId);
    return function cleanup() {
      cleanupRemeshingProject();
    };
  }, [requestedProjectId]);

  return (
    <div style={{ backgroundColor: "steelblue", gridArea: "main" }}>
      {project ? (
        <div>
          <Link to={AppRoutes.RemeshingProjects}>
            {svgIcon(ICON.UNDO)}
            <span>{`Go back to remeshing projects`}</span>
          </Link>
          <h2>{project.name}</h2>
          <div
            style={{
              backgroundColor: "darkgray",
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            <img
              alt={`Flattening img for ${project.id}`}
              src={project.flattening_image_url}
              title={`Flattening image for project ${name}`}
              style={{ maxHeight: "20rem" }}
            />
          </div>
          {/* ORIGINAL MODEL (STL FILE) */}
          {/* {project.original_model_url && (
            <div style={{ backgroundColor: "orange" }}>
              {project.original_model_url}
            </div>
          )} */}
          {/* LAST MODEL (OBJ FILE) */}
          {/* {project.shoe_last_mesh_url && (
            <div style={{ backgroundColor: "orange" }}>
              {project.shoe_last_mesh_url}
            </div>
          )} */}
          {/* LAST FLATTENING */}
          {/* {project.shoe_last_flattening && (
            <div style={{ backgroundColor: "orange" }}>
              {project.shoe_last_flattening}
            </div>
          )} */}
          <ProjectActions
            actions={actions}
            onActionClick={onActionClick}
            projectId={project.id}
          />
          {project.cal_url ? (
            <a
              className={clsx(
                "download-link",
                "margin-left-for-all-children-except-first"
              )}
              download={`${name}.cal`}
              href={project.cal_url}
            >
              <span>Download .cal</span>
              {svgIcon(ICON.SAVE)}
            </a>
          ) : (
            <span>.cal not available</span>
          )}
          <a
            href={`${CADIUS_FORMS_URL}/#/${project.id}`}
            target="_blank"
          >{`Open ${project.name} in cadius-forms`}</a>
        </div>
      ) : (
        <ProjectNotFound
          id={requestedProjectId}
          to={AppRoutes.RemeshingProjects}
          toLabel={`Go back to remeshing projects`}
        />
      )}
    </div>
  );
};
