import { ICON, MenuEntry, svgIcon } from "cadius-components";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

import { User } from "../../contexts";
import { AppRoutes } from "../../routes";

import "./menu.scss";

interface Props extends React.ComponentProps<"div"> {
  onClickLogout: () => void;
  user?: User;
}

/**
 * Menu for cadius-dashboard.
 */
export const Menu: React.FC<Props> = ({
  className,
  onClickLogout,
  style,
  user,
}) => {
  return (
    <div className={clsx(className, "menu")} style={style}>
      <div>
        <Link to={AppRoutes.StyleProjects}>
          <MenuEntry icon={ICON.PROJECTS} label="Manage Projects" />
        </Link>
        <Link to={AppRoutes.RemeshingProjects}>
          <MenuEntry icon={ICON.LASTS} label="Manage Lasts" />
        </Link>
      </div>
      {user && (
        <button
          className={clsx("tool-button")}
          onClick={onClickLogout}
          type="button"
        >
          {svgIcon(ICON.EXIT)}
          <span>Logout</span>
        </button>
      )}
    </div>
  );
};
