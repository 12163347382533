import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { StyleProject } from "../../components/StyleProject";
import {
  fetchStyleProject,
  requestOperationOnStyleProject,
  setStyleProject,
} from "../actions/project";
import { AppState } from "../reducers/interfaces";
import { getStyleProject } from "../reducers/projects";

function mapStateToProps(state: AppState) {
  return {
    project: getStyleProject(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const actionCreators = bindActionCreators(
    {
      cleanupStyleProject: setStyleProject,
      fetchStyleProject,
      onActionClick: requestOperationOnStyleProject,
    },
    dispatch
  );

  return {
    ...actionCreators,
  };
}

const enhance = connect(mapStateToProps, mapDispatchToProps);

export const StyleProjectConnected = enhance(StyleProject);
