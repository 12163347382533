import { CTAButton, WarnButton } from "@comelz/ccloud-react-components";
import { FormFieldWithDropzone, NewLastFormData } from "cadius-components";
import clsx from "clsx";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import "./UploadLastRoute.scss";

interface Props extends RouteComponentProps {
  onSubmitNewLastData: (data: NewLastFormData) => void;
}

export const UploadLastRoute: React.FC<Props> = ({
  onSubmitNewLastData,
}) => {
  const formRef: React.RefObject<HTMLFormElement> = React.createRef();

  const [nameValue, setNameValue] = useState("");
  const [nameError, setNameError] = useState(false);

  const [stlValue, setStlValue] = useState(undefined as Blob | undefined);
  const [stlError, setStlError] = useState(false);

  const [imageValue, setImageValue] = useState(undefined as Blob | undefined);
  const [imageError, setImageError] = useState(false);

  const [notesValue, setNotesValue] = useState("");

  const onCancel = () => {
    formRef.current!.reset();
  };

  const onUpload = (): void => {
    onSubmitNewLastData({
      lastName: nameValue,
      stlFile: stlValue!,

      lastImage: imageValue,
      notes: notesValue,
    });
  };

  const onNameChange = (evt: any) => {
    const value: string = evt.target.value;
    setNameValue(value);
    setNameError(value === "");
  };

  const onNotesChange = (evt: any) => {
    const value: string = evt.target.value;
    setNotesValue(value);
  };

  // submitEnabled controls if the forms can be submitted
  // to be submitted the form must be valid (no errors) and must have all the required values
  const submitEnabled = !(nameError || stlError || imageError) && nameValue !== undefined && stlValue !== undefined;
  return (
    <div className="upload-last">
      <div className="cmp-panel-header">
        <h1>Upload new Last</h1>
      </div>
      <form className="cmp-form" ref={formRef}>
        <div className={clsx("cmp-field", nameError ? "invalid" : "")}>
          <label htmlFor="upload-last-project-name">Last name</label>
          <input
            type="text"
            id="upload-last-project-name"
            name="upload-last-project-name"
            placeholder="The last name"
            onChange={onNameChange}
          />
        </div>
        <div className={clsx("cmp-field", stlError ? "invalid" : "")}>
          <FormFieldWithDropzone
            accept=".stl"
            clearError={() => setStlError(false)}
            errorMessage={stlError ? "unsupported file type" : ""}
            hint="The .stl file to use to create the new last."
            label="Mesh .stl file"
            name="stlFile"
            setError={() => setStlError(true)}
            setValue={(name: string, value: Blob) => setStlValue(value)}
          />
        </div>
        <div className={clsx("cmp-field", imageError ? "invalid" : "")}>
          <FormFieldWithDropzone
            clearError={() => setImageError(false)}
            errorMessage={stlError ? "unsupported file type" : ""}
            hint="The image that represents the Last model to import."
            label="Image (optional)"
            name="imageFile"
            setError={() => setImageError(true)}
            setValue={(name: string, value: Blob) => setImageValue(value)}
          />
        </div>
        <div className="cmp-field">
          <label htmlFor="upload-last-project-notes">Notes (optional)</label>
          <textarea
            id="upload-last-project-notes"
            name="upload-last-project-notes"
            placeholder="Some notes about the Last model you are importing."
            onChange={onNotesChange}
          ></textarea>
        </div>
        <div className="cmp-form-buttons">
          <WarnButton text="cancel" onClick={onCancel} />
          <CTAButton
            text="Upload"
            disabled={!submitEnabled}
            onClick={onUpload}
          />
        </div>
      </form>
    </div>
  );
};
