import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { RemeshingProjectsRoute } from "../../route-components";
import {
  requestOperationOnRemeshingProject,
  startPollingRemeshingProjectDigests,
  stopPollingRemeshingProjectDigests,
} from "../actions/project";
import { toggleBetweenListAndGrid } from "../actions/ui";
import { AppState } from "../reducers/interfaces";
import { getRemeshingProjectDigests } from "../reducers/projects";

function mapStateToProps(state: AppState) {
  return {
    areShownAsGrid: state.ui.areProjectDigestsShownAsGrid,
    projectDigests: getRemeshingProjectDigests(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const actionCreators = bindActionCreators(
    {
      onActionClick: requestOperationOnRemeshingProject,
      onToggleView: toggleBetweenListAndGrid,
      startPollingRemeshingProjectDigests,
      stopPollingRemeshingProjectDigests,
    },
    dispatch
  );

  return {
    ...actionCreators,
  };
}

const enhance = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const RemeshingProjectsRouteConnected = enhance(RemeshingProjectsRoute);
