import { Creds, initClient } from "cadius-backend";
import React from "react";
import ReactDOM from "react-dom";

import { App } from "./components/App";

import "./css/index.css";

declare const DEF_DEBUG_MODE: boolean;
if (DEF_DEBUG_MODE) {
  console.warn(`--- FLAG DEBUG MODE: ${DEF_DEBUG_MODE} ---`);
}

/**
 * Credentials for the picasso client. At the moment we don't have
 * authentication and we simulate 2 users: u01 and s01 (s stands for stylist).
 * To use the application as a stylist, launch it with:
 * http://localhost:8080/?user=s01#/
 */
const creds: Creds =
  document.location.search.indexOf("user=s01") === 1 ? "s01" : "u01";

async function initPicassoClientThenRender() {
  try {
    await initClient(creds);
    ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
  } catch (err) {
    const msg = `Cannot initialize app: ${err.message}`;
    console.error(msg, err);
    alert(msg);
  }
}

initPicassoClientThenRender();
