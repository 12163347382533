import { combineReducers } from "redux";

import { reducer as errorsReducer } from "./errors";
import { reducer as projectsReducer } from "./projects";
import { reducer as uiReducer } from "./ui";

export const rootReducer = combineReducers({
  errors: errorsReducer,
  projects: projectsReducer,
  ui: uiReducer,
});
