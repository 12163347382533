import { Reducer } from "redux";

import { Action, SetLoaderAction, ToggleAction } from "../actions/ui";
import { UiState } from "./interfaces";

type UiAction = SetLoaderAction | ToggleAction;

export const reducer: Reducer<UiState, UiAction> = (
  state = initialState(),
  action
): UiState => {
  switch (action.type) {
    case Action.SET_LOADER: {
      // console.log("TODO: SET_LOADER in reducers/ui.ts", action);
      return {
        ...state,
      };
    }

    case Action.TOGGLE_BETWEEN_LIST_AND_GRID: {
      return {
        ...state,
        areProjectDigestsShownAsGrid: !state.areProjectDigestsShownAsGrid,
      };
    }

    default: {
      return state;
    }
  }
};

export const initialState = (): UiState => {
  return {
    areProjectDigestsShownAsGrid: false,
  };
};
