export enum AppRoutes {
  // Default root we redirect to every time a unauthenticated user tries to
  // access a protected route (i.e. a route which requires an authenticated
  // user).
  DefaultForUnauthenticated = "/",
  Home = "/",
  Login = "/login",
  Register = "/register",
  RemeshingProjects = "/remeshing_projects",
  StyleProjects = "/style_projects",
  UploadCal = "/upload_cal",
  UploadLast = "/upload_last",
}
