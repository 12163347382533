import { PicassoClient } from "cadius-backend";
import React from "react";

// In a user management system we would probably have this data about the user.
// This should stay in cadius-backend, not here.
export interface User {
  company: string;
  email: string;
  name: string;
  profession: string;
}

// The application starts with a unauthenticated user.
export const defaultUser = undefined;

interface IAuthContext {
  picassoClient?: PicassoClient;

  /**
   * The current user. If it's undefined, the user is unauthenticated. Otherwise
   * the user is authenticated.
   */
  user?: User;
}

// Default value for the React Context. It will be used ONLY when a component
// does not have a matching <Context.Provider /> above it in the component tree,
// or when the Context.Provider is not yet mounted in the DOM.
const defaultValue: IAuthContext = {
  picassoClient: undefined,
  user: defaultUser,
};

/**
 * Create a React Context that components can consume, wherever they are in the
 * component hierarchy.
 *
 * @see https://reactjs.org/docs/context.html#reactcreatecontext
 */
export const AuthContext = React.createContext(defaultValue);
