import { Reducer } from "redux";

import { Action, SetErrorAction } from "../actions/error";
import { ErrorsState } from "./interfaces";

export const reducer: Reducer<ErrorsState, SetErrorAction> = (
  state = initialState(),
  action
): ErrorsState => {
  switch (action.type) {
    case Action.SET_NETWORK_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload.error],
      };
    }

    default: {
      return state;
    }
  }
};

export const initialState = (): ErrorsState => {
  return {
    errors: [],
  };
};
