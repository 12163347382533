export enum Action {
  BEGIN_NETWORK_REQUEST = "BEGIN_NETWORK_REQUEST",
  END_NETWORK_REQUEST = "END_NETWORK_REQUEST",
}

export interface NetworkEventAction {
  type: string;
}

export function beginNetworkRequest(): NetworkEventAction {
  return { type: Action.BEGIN_NETWORK_REQUEST };
}

export function endNetworkRequest(): NetworkEventAction {
  return { type: Action.END_NETWORK_REQUEST };
}
