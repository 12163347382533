import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { StyleProjectsRoute } from "../../route-components";
import {
  fetchStyleProject,
  requestOperationOnStyleProject,
  startPollingStyleProjectDigests,
  stopPollingStyleProjectDigests,
} from "../actions/project";
import { toggleBetweenListAndGrid } from "../actions/ui";
import { AppState } from "../reducers/interfaces";
import { getStyleProjectDigests, getStyleProjectId } from "../reducers/projects";

function mapStateToProps(state: AppState) {
  return {
    areShownAsGrid: state.ui.areProjectDigestsShownAsGrid,
    projectDigests: getStyleProjectDigests(state),
    projectId: getStyleProjectId(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  const actionCreators = bindActionCreators(
    {
      onActionClick: requestOperationOnStyleProject,
      onProjectClick: fetchStyleProject,
      onToggleView: toggleBetweenListAndGrid,
      startPollingStyleProjectDigests,
      stopPollingStyleProjectDigests,
    },
    dispatch
  );

  return {
    ...actionCreators,
  };
}

const enhance = connect(mapStateToProps, mapDispatchToProps);

export const StyleProjectsRouteConnected = enhance(StyleProjectsRoute);
