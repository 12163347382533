import { ProjectDigest, RemeshingProject, RemeshingProjectDigest, StyleProject } from "cadius-backend";
import { ItemDataset, NewLastFormData, NewProjectFormData } from "cadius-components";

export enum Action {
  FETCH_REMESHING_PROJECT = "FETCH_REMESHING_PROJECT",
  FETCH_STYLE_PROJECT = "FETCH_STYLE_PROJECT",
  REQUEST_OPERATION_ON_REMESHING_PROJECT = "REQUEST_OPERATION_ON_REMESHING_PROJECT",
  REQUEST_OPERATION_ON_STYLE_PROJECT = "REQUEST_OPERATION_ON_STYLE_PROJECT",
  SET_REMESHING_PROJECT = "SET_REMESHING_PROJECT",
  SET_REMESHING_PROJECT_DIGESTS = "SET_REMESHING_PROJECT_DIGESTS",
  SET_STYLE_PROJECT = "SET_STYLE_PROJECT",
  SET_STYLE_PROJECT_DIGESTS = "SET_STYLE_PROJECT_DIGESTS",
  START_POLLING_REMESHING_PROJECT_DIGESTS = "START_POLLING_REMESHING_PROJECT_DIGESTS",
  START_POLLING_STYLE_PROJECT_DIGESTS = "START_POLLING_STYLE_PROJECT_DIGESTS",
  STOP_POLLING_REMESHING_PROJECT_DIGESTS = "STOP_POLLING_REMESHING_PROJECT_DIGESTS",
  STOP_POLLING_STYLE_PROJECT_DIGESTS = "STOP_POLLING_STYLE_PROJECT_DIGESTS",
  UPLOAD_CAL = "UPLOAD_CAL",
  UPLOAD_LAST = "UPLOAD_LAST",
}

export interface FetchProject {
  payload: {
    projectId: string;
  };
  type: string;
}

export const fetchRemeshingProject = (projectId: string) => {
  return { type: Action.FETCH_REMESHING_PROJECT, payload: { projectId } };
};

export const fetchStyleProject = (projectId: string) => {
  return { type: Action.FETCH_STYLE_PROJECT, payload: { projectId } };
};

export interface RequestOperationOnProject {
  payload: {
    actionId: string;
    projectId: string;
  };
  type: string;
}

export function requestOperationOnRemeshingProject(dataset: ItemDataset) {
  const { actionId, projectId } = dataset;
  return {
    payload: {
      actionId,
      projectId,
    },
    type: Action.REQUEST_OPERATION_ON_REMESHING_PROJECT,
  };
}

export function requestOperationOnStyleProject(dataset: ItemDataset) {
  const { actionId, projectId } = dataset;
  return {
    payload: {
      actionId,
      projectId,
    },
    type: Action.REQUEST_OPERATION_ON_STYLE_PROJECT,
  };
}

export interface StartPollingProjectDigests {
  payload: {
    ms: number;
  };
  type: string;
}

export const startPollingRemeshingProjectDigests = (ms: number) => {
  return { payload: { ms }, type: Action.START_POLLING_REMESHING_PROJECT_DIGESTS };
};

export const startPollingStyleProjectDigests = (ms: number) => {
  return { payload: { ms }, type: Action.START_POLLING_STYLE_PROJECT_DIGESTS };
};

export const stopPollingRemeshingProjectDigests = () => {
  return { type: Action.STOP_POLLING_REMESHING_PROJECT_DIGESTS };
};

export const stopPollingStyleProjectDigests = () => {
  return { type: Action.STOP_POLLING_STYLE_PROJECT_DIGESTS };
};

export interface SetProjectDigests {
  payload: {
    projectDigests: ProjectDigest[];
  };
  type: string;
}

export function setRemeshingProjectDigests(projectDigests: RemeshingProjectDigest[]): SetProjectDigests {
  return { payload: { projectDigests }, type: Action.SET_REMESHING_PROJECT_DIGESTS };
}

export function setStyleProjectDigests(projectDigests: ProjectDigest[]): SetProjectDigests {
  return { payload: { projectDigests }, type: Action.SET_STYLE_PROJECT_DIGESTS };
}

export interface SetProject<T> {
  payload: {
    project: T;
  };
  type: string;
}

export type SetRemeshingProject = SetProject<RemeshingProject | undefined>;

export const setRemeshingProject = (project?: RemeshingProject): SetRemeshingProject => {
  return { type: Action.SET_REMESHING_PROJECT, payload: { project } };
};

export type SetStyleProject = SetProject<StyleProject | undefined>;

export const setStyleProject = (project?: StyleProject): SetStyleProject => {
  return { type: Action.SET_STYLE_PROJECT, payload: { project } };
};

export interface UploadCal {
  payload: {
    calFile: Blob;
    projectName: string;
  };
  type: Action.UPLOAD_CAL;
}

export const uploadCal = (data: NewProjectFormData): UploadCal => {
  return {
    payload: {
      calFile: data.calFile,
      projectName: data.projectName,
    },
    type: Action.UPLOAD_CAL,
  };
};

export interface UploadLast {
  payload: {
    lastImage?: Blob;
    lastName: string;
    notes?: string;
    stlFile: Blob;
  };
  type: Action.UPLOAD_LAST;
}

export const uploadLast = (data: NewLastFormData): UploadLast => {
  return {
    payload: {
      lastImage: data.lastImage,
      lastName: data.lastName,
      notes: data.notes,
      stlFile: data.stlFile,
    },
    type: Action.UPLOAD_LAST,
  };
};

// Project-related actions that should be handled by a middleware.
export type ProjectCommandAction =
  | FetchProject
  | RequestOperationOnProject
  | StartPollingProjectDigests
  | UploadCal
  | UploadLast;

// Project-related actions that should be handled by a reducer.
export type ProjectDocumentAction =
  | SetRemeshingProject
  | SetStyleProject
  | SetProjectDigests;
