export function login() {
  alert("this redirects to the comelz cloud service which handles the login");
}

export function logout() {
  alert("this redirects to the comelz cloud service which handles the logout");
}

export function resetPassword() {
  alert("this redirects to the comelz cloud service which handles the password reset");
}
