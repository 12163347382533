import { CTAButton } from "@comelz/ccloud-react-components";
import { ProjectDigest } from "cadius-backend";
import { ItemDataset } from "cadius-components";
import React, { useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { ProjectList } from "../components/ProjectList";
import { AppRoutes } from "../routes";

export interface Props extends RouteComponentProps {
  areShownAsGrid?: boolean;

  onActionClick: (dataset: ItemDataset) => void;

  onToggleView: () => void;

  projectDigests: ProjectDigest[];

  startPollingStyleProjectDigests: (ms: number) => void;

  stopPollingStyleProjectDigests: () => void;
}

declare const CADIUS_CAD3DR_URL: string;

/**
 * Component rendered when the app is at /style_projects, either because the
 * user navigated to, or because we programmatically redirected to.
 */
export const StyleProjectsRoute: React.FC<Props> = ({
  areShownAsGrid,
  onActionClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onToggleView,
  projectDigests,
  startPollingStyleProjectDigests,
  stopPollingStyleProjectDigests,
}) => {
  useEffect(() => {
    startPollingStyleProjectDigests(10000);
    return () => {
      stopPollingStyleProjectDigests();
    };
  }, []);

  const onDelete = (projectId: string) => {
    onActionClick({
      actionId: "delete",
      projectId,
    });
  };

  const onClick = (projectId: string) => {
    const url = `${CADIUS_CAD3DR_URL}/#/projects/${projectId}`;
    window.open(url, "_blank");
  };

  const projectActions = (prj: ProjectDigest) => {
    const handler = (evt: any) => {
      evt.preventDefault();
      const msg = `Are you sure to delete "${prj.name}"`;
      if (confirm(msg)) {
        onDelete(prj.id);
      }
    };
    return (
      <div className="actions">
        <a href="#" onClick={handler}>remove</a>
      </div>);
  };

  return (
    <div>
      <div className="cmp-panel-header">
        <h1>Style projects</h1>
        <Link to={AppRoutes.UploadCal}>
          <CTAButton text="Upload new CAL" />
        </Link>
      </div>
      <div>
        {areShownAsGrid ? (
          "grid"
          // <ProjectGrid
          //     onProjectClick={onProjectClick}
          //     onActionClick={onActionClick}
          //     projects={projectDigests.map(toProject)}
          //     style={{ backgroundColor: "darkgray" }}
          // />
        ) : (
          <ProjectList
            projects={projectDigests}
            onClick={onClick}
            actions={projectActions}
          />
        )}
      </div>
    </div>);
};
