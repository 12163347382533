import { Reducer } from "redux";

import {
  Action,
  ProjectDocumentAction,
  SetProjectDigests,
  SetRemeshingProject,
  SetStyleProject,
} from "../actions/project";
import { UploadAction } from "../actions/ui";
import { AppState, ProjectsState } from "./interfaces";

export const reducer: Reducer<ProjectsState, ProjectDocumentAction> = (
  state = initialState(),
  action
): ProjectsState => {
  switch (action.type) {
    case Action.SET_REMESHING_PROJECT_DIGESTS: {
      const { payload } = action as SetProjectDigests;
      return {
        ...state,
        remeshingDigests: payload.projectDigests,
      };
    }

    case Action.SET_STYLE_PROJECT_DIGESTS: {
      const { payload } = action as SetProjectDigests;
      return {
        ...state,
        styleDigests: payload.projectDigests,
      };
    }

    case Action.SET_REMESHING_PROJECT: {
      const { payload } = action as SetRemeshingProject;
      return {
        ...state,
        remeshing: payload.project,
        remeshingId: payload.project?.id,
      };
    }

    case Action.SET_STYLE_PROJECT: {
      const { payload } = action as SetStyleProject;
      return {
        ...state,
        style: payload.project,
        styleId: payload.project?.id,
      };
    }

    case "SET_UPLOAD_IN_PROGRESS": {
      const { payload } = action as UploadAction;
      return {
        ...state,
        remeshingUploadInProgress: payload.target === "remeshing",
        styleUploadInProgress: payload.target === "style",
      };
    }
    default: {
      return state;
    }
  }
};

export const initialState = (): ProjectsState => {
  return {
    remeshingDigests: [],
    remeshingUploadInProgress: false,
    styleDigests: [],
    styleUploadInProgress: false,
  };
};

export const getRemeshingProjectDigests = (state: AppState) => {
  return state.projects.remeshingDigests;
};

export const getRemeshingProject = (state: AppState) => {
  return state.projects.remeshing;
};

export const getRemeshingProjectId = (state: AppState) => {
  return state.projects.remeshing?.id;
};

export const getStyleProjectDigests = (state: AppState) => {
  return state.projects.styleDigests;
};

export const getStyleProject = (state: AppState) => {
  return state.projects.style;
};

export const getStyleProjectId = (state: AppState) => {
  return state.projects.style?.id;
};
