import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { UploadCalRoute } from "../../route-components";
import { uploadCal } from "../actions/project";

function mapDispatchToProps(dispatch: Dispatch) {
  const actionCreators = bindActionCreators(
    {
      onSubmitNewProjectData: uploadCal,
    },
    dispatch
  );

  return {
    ...actionCreators,
  };
}

const enhance = connect(null, mapDispatchToProps);

export const UploadCalRouteConnected = enhance(UploadCalRoute);
