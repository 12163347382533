export enum Action {
  SET_NETWORK_ERROR = "SET_NETWORK_ERROR",
}

export interface SetErrorAction {
  payload: {
    error: Error;
  };
  type: string;
}

/**
 * Convert an error caused by a network request - which could have any shape -
 * into an error with a very specific shape that this application knows how to
 * handle.
 */
export function setNetworkError(err: any, message: string): SetErrorAction {
  const obj = {
    // The service we made our request to might have not included a message in
    // its response payload. This application knows what it was trying to do, so
    // at least we can specify that in the error message.
    message: err.message || message,

    // If the network request fails but `err` does not contain a HTTP status
    // code, we assume it's a case not handled by the service we made our
    // request to.
    status: err.status || 500,
  };

  const error = new Error(JSON.stringify(obj, null, 2));

  return { payload: { error }, type: Action.SET_NETWORK_ERROR };
}
