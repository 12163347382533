import { client, PicassoClient } from "cadius-backend";
import React, { useEffect, useState } from "react";

import { AuthContext, User } from "../contexts";

const modeller = {
  company: "comelz",
  email: "simone@comelz.com",
  name: "Simone",
  profession: "modeller",
};

/**
 * Component that modifies the AuthContext and provides the updated context to
 * all AuthContext consumers down in the component tree.
 *
 * This component should wrap <App />, so the updated AuthContext will be
 * available to all components in the tree.
 */
export const AuthContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [picassoClient, setPicassoClient] = useState<PicassoClient>();

  useEffect(() => {
    setUser(modeller);
    setPicassoClient(client());
  }, []);

  const value = {
    picassoClient,
    user,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
